import React from "react";
import { Row, Col } from "react-bootstrap";

const EmbedVideo = ({ slice }) => {
  return (
    <Row className="youtube-videos my-5">
      {slice.items.map((item) => {
        return (
          <Col
            key={item.video.id}
            dangerouslySetInnerHTML={{ __html: item.video.html }}
            md={6}
            className="mb-4"
          />
        );
      })}
    </Row>
  );
};

export default EmbedVideo;
