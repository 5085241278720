import Promo from "./Promo";
import HomeAbout from "./HomeAbout";
import Testimonials from "./Testimonials";
import DownloadFiles from "./DownloadFiles";
import RichText from "./RichText";
import Gallery from "./Gallery";
import Gallery1 from "./Gallery1";
import IconTextCenter from "./IconTextCenter";
import Showcase from "./Showcase";
import SingleImage from "./SingleImage";
import CallButtons from "./CallButtons";
import SingleTitle from "./SingleTitle";
import SingleVideo from "./SingleVideo";
import Tabs from "./Tabs";
import EmbedVideo from "./EmbedVideo";

const components = {
  promo_section: Promo,
  home_about: HomeAbout,
  testimonials_slider: Testimonials,
  download_files: DownloadFiles,
  rich_text: RichText,
  gallery: Gallery,
  gallery_1: Gallery1,
  icon_text_center: IconTextCenter,
  product_showcase: Showcase,
  single_image: SingleImage,
  title_buttons: CallButtons,
  single_title: SingleTitle,
  single_video: SingleVideo,
  tabs: Tabs,
  embed_video: EmbedVideo,
};

export default components;
