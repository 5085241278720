import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const CallButtons = ({ slice }) => {
  return (
    <div className="my-4 py-4 py-sm-5 px-3 px-sm-5 bg-danger br20 d-flex flex-column align-items-center">
      <h3 className="mb-4">{slice.primary.banner_title}</h3>
      <div className="d-flex flex-column flex-sm-row">
        {slice.items.map((item, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="bg-white br29 mx-3 mb-4">
              <Anchor
                href={item.button_link.url}
                className="custom-tel-btn d-flex align-items-center"
              >
                <div className="tel-icon-wraper d-flex justify-content-center align-items-center">
                  <div style={{ height: "14px" }}>
                    <StaticImage
                      src="../../images/icons/phone.png"
                      alt="call button"
                    />
                  </div>
                </div>
                <h5 className="mb-0 mx-3">{item.button_label}</h5>
              </Anchor>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CallButtons;
