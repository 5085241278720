import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const SingleImage = ({ slice }) => {
  const { image } = slice.primary;
  return (
    <div className="my-5">
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt || "project image"}
        className="d-flex br20"
      />
    </div>
  );
};

export default SingleImage;
