import React, { useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Offcanvas } from "react-bootstrap";
import CustomButton from "../elements/CustomButton";

const Gallery1 = ({ slice }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const offCanvasGallery = (
    <Offcanvas
      show={isGalleryOpen}
      onHide={() => setIsGalleryOpen(false)}
      placement="start"
      backdrop="false"
      className="gallery-canvas"
    >
      <Offcanvas.Body>
        <div className="gallery-body">
          <button
            className="close-gallery-btn p-4"
            type="button"
            onClick={() => setIsGalleryOpen(false)}
          >
            <StaticImage
              src="../../images/icons/redRightArrow.png"
              alt="close products button"
            />
          </button>
          <div className="d-flex flex-column align-items-center py-2">
            {slice.items.map((image, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-4">
                  <GatsbyImage
                    image={image.image.gatsbyImageData}
                    alt={image.image.alt || "gallery image"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );

  if (slice.items.length === 0) return null;

  return (
    <>
      <div className="row-image-gallery my-5">
        <div className="image-wrapper d-flex gap-4">
          {slice.items.slice(0, 3).map((image, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                <GatsbyImage
                  image={image.image.gatsbyImageData}
                  alt={image.image.alt || "service image"}
                />
              </div>
            );
          })}
        </div>
        <button type="button" onClick={() => setIsGalleryOpen(true)}>
          <CustomButton label={slice.primary.button_label} />
        </button>
      </div>
      {offCanvasGallery}
    </>
  );
};

export default Gallery1;
