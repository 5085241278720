import React from "react";
import { PrismicRichText } from "@prismicio/react";
import CustomButtom from "../elements/CustomButton";

const RichText = ({ slice }) => {
  return (
    <div
      className={`rich-text-slice-container my-4 py-4 py-sm-5 px-3 px-sm-5
        ${slice.primary.is_red_important ? "bg-danger" : "bg-light"}`}
    >
      <PrismicRichText field={slice.primary.text_description.richText} />
      {slice.primary.custom_button_label && (
        <CustomButtom
          url={slice.primary.custom_button_link.url}
          label={slice.primary.custom_button_label}
        />
      )}
    </div>
  );
};

export default RichText;
