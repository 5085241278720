import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const DownloadFiles = ({ slice }) => {
  return (
    <div className="my-5">
      {slice.items.map((link, i) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="d-flex align-items-center"
            style={{ maxWidth: 650 }}
          >
            <Anchor
              href={link.link_to_file.url}
              className="d-flex align-items-center text-primary"
              target="_blank"
            >
              <div>
                <StaticImage
                  src="../../images/icons/download.png"
                  alt="download icon"
                  style={{ minWidth: 41 }}
                />
              </div>
              <strong className="ms-3">{link.label}</strong>
            </Anchor>
          </div>
        );
      })}
    </div>
  );
};

export default DownloadFiles;
