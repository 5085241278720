import React, { useState, useEffect } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Offcanvas } from "react-bootstrap";
// import CustomButton from "../elements/CustomButton";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";

const Gallery = ({ slice }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  let numberOfCols;
  const numberOfColumns = slice.primary.number_of_columns;
  if (numberOfColumns) {
    numberOfCols = numberOfColumns;
  } else {
    numberOfCols = 3;
  }

  const handleImageClick = (imgIndex) => {
    setModalOpen(true);
    setCurrentImage(imgIndex);
  };

  const nextIconCustom = (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32983 0.584778L10.6703 11.003L1.32983 20.5231"
        stroke="black"
        strokeWidth="0.889569"
      />
    </svg>
  );

  const prevIconCustom = (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6702 20.5231L1.32969 10.1049L10.6702 0.58481"
        stroke="black"
        strokeWidth="0.889569"
      />
    </svg>
  );

  // const offCanvasGallery = (
  //   <Offcanvas
  //     show={isGalleryOpen}
  //     onHide={() => setIsGalleryOpen(false)}
  //     placement="start"
  //     backdrop="false"
  //     className="gallery-canvas"
  //   >
  //     <Offcanvas.Body>
  //       <div className="gallery-body">
  //         <button
  //           className="close-gallery-btn p-4"
  //           type="button"
  //           onClick={() => setIsGalleryOpen(false)}
  //         >
  //           <StaticImage
  //             src="../../images/icons/redRightArrow.png"
  //             alt="close products button"
  //           />
  //         </button>
  //         <div className="d-flex flex-column align-items-center py-2">
  //           {slice.items.map((image, i) => {
  //             return (
  //               // eslint-disable-next-line react/no-array-index-key
  //               <div key={i} className="mb-4">
  //                 <GatsbyImage
  //                   image={image.image.gatsbyImageData}
  //                   alt={image.image.alt || "gallery image"}
  //                 />
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </Offcanvas.Body>
  //   </Offcanvas>
  // );

  const galleryModal = (
    <Modal
      show={modalOpen}
      onHide={() => setModalOpen(false)}
      className="gallery-modal"
      scrollable={false}
    >
      <Modal.Header closeButton closeVariant="white" />
      <Modal.Body>
        <Carousel
          interval={null}
          nextIcon={nextIconCustom}
          prevIcon={prevIconCustom}
          activeIndex={currentImage}
          onSelect={(curIm) => {
            setCurrentImage(curIm);
          }}
        >
          {slice.items.map((item) => (
            <Carousel.Item>
              <div className="carousel-item-container">
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt || "gallery image"}
                  fluid={{
                    maxHeight: "600px",
                    quality: 100,
                  }}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );

  if (slice.items.length === 0) return null;

  return (
    <>
      <Container>
        {/* sm={1} md={2} lg={3} xl={4} xxl={5} */}
        <Row
          xs={1}
          sm={1}
          md={2}
          lg={3}
          xl={numberOfCols >= 4 && 4}
          xxl={numberOfCols >= 5 && numberOfCols}
          className="grid-image-gallery"
        >
          {slice.items.map((item, index) => {
            return (
              <Col
                key={index}
                className="mb-4 d-flex justify-content-center align-items-center"
              >
                <div
                  className="p-2 galleryImage"
                  onClick={() => handleImageClick(index)}
                >
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt || "gallery image"}
                    height="50vh"
                    width="auto"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {galleryModal}
    </>
  );
};;

export default Gallery;
